<template>
<div class="bg-light pt-150">
    <div class="container" >
      <div class="col">
        <div style="margin-top:100px;">
          <div class="col-lg-5 p-5 mx-auto login-bg mt-5 mb-5" style="box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.52)">
            <h1 class="text-primary mb-4 text-center" style="font-size:1.5rem !important;">
              <strong>Reset password request</strong>
            </h1>
            <form ref="login-form">
              <div label-for="input-1">
                <v-text-field type="text" v-model="email" @input="validate.emailValidation(email, 'email')"  outlined><template #label>Email<span class="red--text"><strong>* </strong></span></template></v-text-field>
                <div class="error-feedback" v-if="typeof validate.response.email != 'undefined' && !validate.response.email.val">
                  {{ validate.response.email.msg }}
                </div>
                <div class="error-feedback" v-if="error_msg !== ''">
                  {{ error_msg }}
                </div>
              </div>
              <div class="success-feedback" v-if="success_msg">{{success_msg}}</div>
              <div class="text-center mt-5">
                <b-button class="mr-3 text-center w-100 mb-5" :disabled="email == ''" variant="primary" @click="sendResetLink()">Request</b-button>
                <a href="mailto:recruit@hireclap.com" v-if="write_to_admin">Write to admin</a>
                <a href="/jobseeker/login">Back to login</a>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import validation from '../libraries/validFormat' ;
import Actions from '../libraries/apiActions.js';
import '../assets/hire.css';
export default {
  name: "forgot_password_request",
  data(){
    return {
      action: new Actions(),
      validate: new validation(),
      email: '',
      success_msg: '',
      can_login: 0,
      error_msg: '',
      write_to_admin: false
    }
  },
  beforeMount(){
    this.token = this.$route.query.token;
  },
  methods: {
   sendResetLink: function(){
      this.can_login = 0;
      this.success_msg = '';
      this.error_msg = '';
      this.write_to_admin = false;
      if (this.validate.emailValidation(this.email, "email")?.val === false) {
        this.can_login++;
      }
      if(this.can_login == 0) {
        this.action
            .sendResetLink({
              'email': this.email
            })
            .then(res => {
              if(res.status == true) {
                this.success_msg = 'Reset link has been sent to your email';
                if(res.write_to_admin == true)  {
                  this.write_to_admin = true;
                  this.success_msg = '';
                }
              } else {
                this.error_msg = res.message;
              }
            })
      }
    },
  }
}
</script>
